import { Spinner, Stack } from "@chakra-ui/react";
import { useLayoutUtils } from "common/hooks/use-breakpoints";

export const BusinessProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { innerHeight } = useLayoutUtils();

  const showLoader = false;

  return showLoader ? (
    <Stack height={innerHeight} alignItems="center" justifyContent="Center">
      <Spinner color="white" />
    </Stack>
  ) : (
    <>{children}</>
  );
};
