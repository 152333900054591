import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useShouldCompleteProfile } from "user/utils";

export const Banners: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const isProfileIncomplete = useShouldCompleteProfile();

  return (
    <Stack spacing={4}>
      {isProfileIncomplete && (
        <Card>
          <CardBody p={1}>
            <Alert status="warning" rounded="md">
              <AlertIcon />
              <AlertTitle>{t("alert.completeProfile")}</AlertTitle>
            </Alert>
          </CardBody>
        </Card>
      )}
    </Stack>
  );
};
