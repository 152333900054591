import { useBreakpointValue } from "@chakra-ui/react";
import { atom, useRecoilState } from "recoil";

const globalHeight = atom({ key: "globalHeight", default: window.innerHeight });
export const useLayoutUtils = () => {
  const mdUp = useBreakpointValue({
    md: true,
  });
  const [height, setGlobalHeight] = useRecoilState(globalHeight);

  return { mdUp, innerHeight: height, setGlobalHeight };
};
