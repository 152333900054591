import { SupProfile } from "auth/atoms";
import { BookingStateAtom } from "./atoms";
import { useAuth } from "auth/hooks/use-auth";

export const MINIMUM_BOARDS_NR = 1;
export const MAXIMUM_BOARDS_NR = 4;
export const MINIMUM_DURATION = 1;
export const MAXIMUM_DURATION = 3;

export const useShouldCompleteProfile = () => {
  const { profile, isOnefitAccount } = useAuth();
  if (!profile || isOnefitAccount) {
    return false;
  }

  if (
    !profile.firstName ||
    !profile.lastName ||
    !profile.phoneNumber ||
    !profile.street ||
    !profile.country ||
    !profile.gender ||
    !profile.postcode ||
    !profile.city ||
    !profile.language ||
    !profile.dateOfBirth
  ) {
    return true;
  }
  return false;
};

export const getUserFromSession = (profile: SupProfile) => {
  return profile || null;
};

export const isUserLoggedin = (profile: SupProfile) => {
  return !!getUserFromSession(profile);
};

export const isBookingSessionComplete = (booking: BookingStateAtom) => {
  if (
    booking?.locationId &&
    booking?.boardsNumber &&
    booking?.date &&
    booking?.time
  ) {
    return true;
  }

  return false;
};

export const TIMEZONE = "Europe/Amsterdam";
